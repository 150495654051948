import { FotterText, FotterWrapper} from "./Fotter.style";
import React  from 'react';

const Fotter = () => {
  return (
    <>
        <FotterWrapper>
          <FotterText>gopalraut9840285859@gmail.com</FotterText>
          <FotterText>+977-9840285859</FotterText>
          <FotterText>Tinkune, kathmandu-32</FotterText>
        </FotterWrapper>
    </>
  )
}

export default Fotter